/* eslint-disable import/no-anonymous-default-export */

export const DEFAULT_DEPOSIT_PRICE = 0.5;
export const DEFAULT_EVAL_PRICE = 150;
export const DEFAULT_DEPOSIT_PRICE_INN = 50;
export const DEFAULT_DEPOSIT_PRICE_OON = 175;

const evaluationPriceTier = {
  omckfw: 175, // 88% discount
  czacgv: 114, // 76% discount
  twlfii: 96, // 64% discount
  guboxk: 78, // 52% discount
  zkrefm: 60, // 40% discount
};

const getPriceTier = tier => {
  return evaluationPriceTier[tier];
};

const toCurrencyFormat = number => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
};

export default { getPriceTier, toCurrencyFormat };
